import Brightness7Icon from '@mui/icons-material/Brightness7';
import ModeNightIcon from '@mui/icons-material/ModeNight';
import { useTheme } from '@mui/material/styles';
import { IconButton, Button, Popper } from "@mui/material";
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import PaletteIcon from '@mui/icons-material/Palette';
import { useState } from "react";

function ColorThemeButton({handleThemeClicked}){
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleTooltipClose = () => {
        setAnchorEl(null);
    };
    
    const handleTooltipOpen = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl)
    const id = open ? 'simple-popper' : undefined;

    return (
        <>
            <ClickAwayListener onClickAway={handleTooltipClose}>
                <div style={{display: "inline"}}>
                    <Popper id={id} open={open} anchorEl={anchorEl}>
                        <ButtonGroup variant="contained">
                            <Button onClick={()=>{handleThemeClicked("halloween")}}>
                                <svg 
                                    width="30px" height="30px" viewBox="0 0 300.000000 300.000000">
                                    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill={theme.palette.text.primary}>
                                    <path d="M1780 2866 c-200 -70 -250 -137 -305 -412 -14 -70 -25 -140 -25 -156
                                        l0 -28 119 0 c66 0 122 4 125 8 2 4 -2 39 -11 77 -8 39 -16 109 -16 156 -2 74
                                        1 90 18 109 27 30 76 50 124 50 l39 0 6 68 c11 123 11 145 1 148 -6 2 -39 -7
                                        -75 -20z"/>
                                        <path d="M745 2223 c-154 -19 -350 -154 -478 -329 -34 -46 -108 -197 -132
                                        -269 -76 -229 -74 -544 6 -775 98 -284 313 -506 554 -570 77 -21 325 -41 325
                                        -27 0 4 -19 16 -42 28 -74 38 -158 99 -236 173 -417 394 -470 1024 -125 1483
                                        61 82 182 197 261 249 28 19 52 37 52 40 0 5 -140 3 -185 -3z"/>
                                        <path d="M1022 2186 c-237 -128 -431 -368 -505 -626 -153 -528 112 -1067 627
                                        -1274 49 -20 99 -36 112 -36 23 0 23 0 -12 60 -197 336 -253 1012 -123 1490
                                        41 148 104 299 152 364 44 59 47 56 -75 56 -109 0 -115 -2 -176 -34z"/>
                                        <path d="M1387 2170 c-99 -110 -173 -303 -223 -585 -28 -156 -26 -576 4 -742
                                        46 -253 112 -425 214 -556 l30 -37 158 0 158 0 37 42 c100 117 191 380 224
                                        648 16 129 14 484 -4 609 -40 278 -119 493 -228 624 l-40 47 -143 0 -142 0
                                        -45 -50z"/>
                                        <path d="M1844 2198 c99 -139 173 -348 217 -613 30 -175 32 -541 5 -714 -33
                                        -219 -84 -392 -156 -529 l-38 -74 26 7 c50 12 207 94 267 140 226 171 371 404
                                        419 670 22 123 14 316 -18 439 -72 279 -256 514 -517 662 -55 31 -68 34 -141
                                        34 l-80 0 16 -22z"/>
                                        <path d="M2198 2184 c204 -137 378 -385 449 -639 26 -93 28 -112 27 -285 0
                                        -157 -3 -196 -21 -262 -87 -311 -259 -538 -538 -707 l-70 -43 90 5 c172 11
                                        323 82 455 216 157 158 247 349 285 598 19 130 19 216 0 345 -19 125 -36 188
                                        -83 298 -81 195 -229 362 -390 444 -72 36 -168 66 -217 66 l-40 0 53 -36z"/>
                                    </g>
                                </svg>
                            </Button>
                            <Button onClick={()=>{handleThemeClicked("cherry")}}>
                                <svg 
                                    width="30px" height="30px" viewBox="0 0 300.000000 300.000000">
                                    <g transform="translate(0.000000,300.000000) scale(0.100000,-0.100000)" fill={theme.palette.text.primary}>
                                    <path d="M1680 2884 c0 -55 35 -243 61 -324 108 -341 295 -476 869 -623 74
                                        -19 139 -31 143 -27 18 16 -33 264 -82 404 -66 187 -214 330 -441 423 -115 47
                                        -501 163 -542 163 -5 0 -8 -7 -8 -16z"/>
                                        <path d="M1533 2791 c-117 -69 -168 -109 -249 -193 -151 -155 -252 -370 -309
                                        -658 -9 -47 -18 -92 -21 -101 -4 -14 -25 -17 -122 -21 -209 -9 -368 -81 -522
                                        -237 -211 -213 -279 -510 -184 -802 52 -160 197 -338 349 -427 136 -79 319
                                        -121 469 -107 l75 7 -23 37 c-44 69 -103 195 -129 276 -96 294 -41 566 161
                                        803 52 61 192 181 283 243 l62 42 -29 24 c-47 37 -153 89 -231 113 -65 19 -72
                                        24 -68 43 96 464 146 580 322 748 44 42 122 104 172 138 81 54 91 65 91 91 0
                                        17 -3 30 -7 30 -5 0 -45 -22 -90 -49z"/>
                                        <path d="M1588 2649 c-93 -250 -112 -362 -105 -595 5 -163 12 -210 62 -405 6
                                        -24 4 -27 -32 -34 -124 -23 -293 -125 -405 -244 -151 -162 -218 -330 -218
                                        -549 0 -350 231 -659 560 -749 49 -14 101 -18 215 -18 143 0 154 2 244 33 264
                                        92 454 295 521 557 28 109 28 282 -1 391 -32 122 -101 245 -189 338 -148 156
                                        -316 236 -533 254 l-81 7 -22 90 c-75 314 -70 523 18 775 43 122 47 157 25
                                        230 l-13 45 -46 -126z"/>
                                    </g>
                                </svg>
                            </Button>
                            <Button onClick={()=>{handleThemeClicked("light")}}><Brightness7Icon style={{ fontSize: "30px", margin: "0", color: theme.palette.text.primary }}></Brightness7Icon></Button>
                            <Button onClick={()=>{handleThemeClicked("dark")}}><ModeNightIcon style={{ fontSize: "30px", margin: "0", color: theme.palette.text.primary }}></ModeNightIcon></Button>
                        </ButtonGroup>
                    </Popper>
                
                    <IconButton onClick={handleTooltipOpen} style={{color: theme.palette.text.primary, marginRight:"10px"}}>
                        <PaletteIcon style={{ fontSize: "30px", margin: "0" }}></PaletteIcon>
                    </IconButton>
                </div>
            </ClickAwayListener>
        </>
    )
}

export default ColorThemeButton;